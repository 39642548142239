var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card",on:{"click":function($event){return _vm.$emit('acao')}}},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"d-flex"},[_c('span',[_vm._v(_vm._s(_vm.servico.embarcacao.nome))]),_c('i',{staticClass:"fa fa-ship",staticStyle:{"margin-left":"auto"}})])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mb-2"},[_c('div',[_vm._v("Status: "+_vm._s(_vm.status))])]),_c('div',{staticClass:"col-12 mb-2"},[_c('div',[_vm._v("Data de Solicitação: "+_vm._s(new Date(_vm.atual.dt_criacao).toLocaleString()))])]),_c('div',{staticClass:"col-12 mb-2"},[_c('div',[_vm._v("Horário de Início: "+_vm._s(new Date(_vm.atual.horario_previsto).toLocaleString()))])]),_c('div',{staticClass:"col-12 mb-2"},[_c('div',[_vm._v("Horário de Conclusão: "+_vm._s(new Date(_vm.atual.horario_termino).toLocaleString()))])]),_c('div',{staticClass:"col-12 mb-2"},[_c('div',[_vm._v("Solicitante: "+_vm._s(_vm.atual.usuario ? _vm.atual.usuario.name : 'Automático'))])]),_vm._m(0),_c('div',{staticClass:"row"},[(_vm.abastecer)?_c('div',{class:'col-6 text-center mb-3 ' + _vm.abastecerStatus},[_vm._m(1),_c('span',[_vm._v(_vm._s(_vm.quantidadeAbastecimento))])]):_vm._e(),(_vm.aguaDoce)?_c('div',{class:'col-6 text-center mb-3 ' + _vm.aguaDoceStatus},[_vm._m(2),_c('span',[_vm._v("Água doce")])]):_vm._e(),(_vm.cleanBoat)?_c('div',{class:'col-6 text-center mb-3 ' + _vm.cleanBoatStatus},[_vm._m(3),_c('span',[_vm._v("Limpeza do barco")])]):_vm._e(),(_vm.oleo2t)?_c('div',{class:'col-6 text-center mb-3 ' + _vm.oleo2tStatus},[_vm._m(4),_c('span',[_vm._v("Óleo 2T")])]):_vm._e(),(_vm.giroMotor)?_c('div',{class:'col-6 text-center mb-3 ' + _vm.giroMotorStatus},[_vm._m(5),_c('span',[_vm._v("Giro de motor")])]):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 mb-2"},[_c('h4',{staticClass:"text-center",staticStyle:{"font-weight":"400"}},[_vm._v(" Serviços ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-lista"},[_c('i',{staticClass:"fas fa-gas-pump fa-2x"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-lista"},[_c('i',{staticClass:"fas fa-glass-martini fa-2x"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-lista"},[_c('i',{staticClass:"fas fa-shower fa-2x"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-lista"},[_c('i',{staticClass:"fas fa-oil-can fa-2x"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-lista"},[_c('i',{staticClass:"fas fa-cog fa-2x"})])
}]

export { render, staticRenderFns }