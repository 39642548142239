<template>
  <Busca ref="busca" url="/permissoes" metodo="get" @dados="dados">
    <template slot="res">
      <div>
        <h4 class="text-center" style="color: #003760">Permissões</h4>
        <div class="card p-3" v-if="embarcacoes.length > 0">
          Adicionar pessoa
          <input type="number" placeholder="CPF/CNPJ" v-model="cpfcnpj" />
          <select
            name=""
            id=""
            style="margin-top: 15px; height: 30px; margin-bottom: 15px"
            v-if="embarcacoes.length > 0"
            v-model="embarcacao"
          >
            <option :value="null">Selecione uma embarcação</option>
            <option :value="e.id" v-for="(e, i) in embarcacoes" :key="i">
              {{ e.nome }}
            </option>
          </select>
          <button
            type="button"
            class="btn btn-primary btn-block w-100 py-3"
            style="max-height: 38px; overflow: hidden"
            @click="permitir"
            ref="enter"
            :disabled="cpfcnpj.length < 10 || embarcacao == null || permitindo"
          >
            <div
              class="lds-ellipsis"
              style="margin-top: -27px"
              v-if="permitindo"
            >
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div v-else>Permitir</div>
          </button>
        </div>
        <div v-else>
          <h5 class="text-center mt-5" style="color: #003760">
            Você não possui embarcações
          </h5>
        </div>
        <div v-for="(e, i) in permissoes" :key="i">
          <div class="card py-1 card-item" v-if="e.show">
            <div class="card-body">
              <div class="row">
                <div class="col-8">
                  <div>
                    <span> {{ e.pessoa.nome }}</span>
                  </div>
                  <div>
                    <span> {{ e.embarcacao.nome }}</span>
                  </div>
                </div>
                <div class="col-4 text-right">
                  <div class="d-flex h-100">
                    <div
                      style="margin: auto 10px auto auto"
                      @click="tirarPermissao(e)"
                    >
                      <i class="fas fa-trash"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Busca>
</template>

<script>
import Busca from "../Busca.vue";
export default {
  components: {
    Busca,
  },
  data() {
    return {
      permissoes: [],
      cpfcnpj: "",
      embarcacoes: [],
      permitindo: false,
      embarcacao: null,
    };
  },
  methods: {
     permitir() {
      this.permitindo = true;
      api(
        "/permitir",
        "post",
        {
          cpfcnpj: this.cpfcnpj,
          embarcacao: this.embarcacao,
        },
        (e) => {
          if (e.success) {
            var e = this.$parent.$parent.$parent;
            this.$parent.$parent.$parent.$parent.notificaoSucesso('Usuário permitido');
            this.$refs.busca.buscar();
          } else {
            this.$parent.$parent.$parent.$parent.notificaoErro(e.message);
          }
          this.permitindo = false;
        },
        (e) => {
          this.$parent.$parent.$parent.$parent.notificaoErro('Um erro ocorreu');
          this.permitindo = false;
        },
        (e) => {
          this.$parent.$parent.$parent.$parent.notificaoErro('Um erro ocorreu');
          this.permitindo = false;
        }
      );
    },
    dados(e) {
      this.permissoes = e.permissoes;
      this.embarcacoes = e.embarcacoes;
      for (const key in this.permissoes) {
        this.permissoes[key].show = true;
      }
    },
    tirarPermissao(permissao) {
      this.tirandoPermissao = true;
      permissao.show = false;
      this.$forceUpdate();
      api(
        "/tirar-permissao",
        "post",
        {
          embarcacao: permissao.embarcacao.id,
          pessoa: permissao.pessoa.id,
        },
        (e) => {
          //   debugger
          if (e.success) {
            this.$parent.$parent.$parent.$parent.notificaoSucesso(e.message);
          } else {
            permissao.show = true;
            this.$forceUpdate();
            this.$parent.$parent.$parent.$parent.notificaoErro(e.message);
          }
          this.tirandoPermissao = false;
        },
        (e) => {
          permissao.show = true;
          this.$forceUpdate();
          this.tirandoPermissao = false;
        },
        (e) => {
          permissao.show = true;
          this.$forceUpdate();
          this.tirandoPermissao = false;
        }
      );
    },
  },
};
</script>