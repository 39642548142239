<template>
  <div class="box">
    <div class="d-flex box-head">
      <div style="position: fixed; left: 10px; top: 25px; font-size: 18px" v-if="back" @click="$emit('back')">
        <i class="fas fa-chevron-left"></i> Voltar
      </div>
      <img
        src="/img/logo2.png"
        alt=""
        class="logo"
        style="width: 100px"
        onload="this.classList.add('show')"
      />
    </div>
    <div class="box-body py-3">
      <slot name="body"></slot>
    </div>
    <div class="box-foot show">
      <div class="div-inferior">
        <button
          :class="'botao-inferior' + (acao == 'Main' ? ' ativo' : '')"
          @click="
            $emit('acao', 'Main');
            acao = 'Main';
          "
        >
          <i class="fa fa-home"></i>
        </button>
      </div>
      <div class="div-inferior">
        <button
          :class="'botao-inferior' + (acao == 'Solicitacao' ? ' ativo' : '')"
          @click="
            $emit('acao', 'Solicitacao');
            acao = 'Solicitacao';
          "
        >
          <i class="fa fa-cog"></i>
        </button>
      </div>
      <div class="div-inferior">
        <button
          :class="'botao-inferior' + (acao == 'Lista' ? ' ativo' : '')"
          @click="
            $emit('acao', 'Lista');
            acao = 'Lista';
          "
        >
          <i class="fa fa-history"></i>
        </button>
      </div>
      <div class="div-inferior">
        <button
          :class="'botao-inferior' + (acao == 'Conta' ? ' ativo' : '')"
          @click="
            $emit('acao', 'Conta');
            acao = 'Conta';
          "
        >
          <i class="fa fa-user"></i>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      acao: "Main",
      back: false,
    };
  },
};
</script>