<template>
  <div class="container h-100" style="max-height: 100%; overflow-y: auto">
    <div v-if="acao == 'lista'">
      <div class="card py-4 card-item" @click="acao = 'permissoes'">
        <div class="card-body">
          <i class="fas fa-user-friends"></i> Permissões
        </div>
      </div>
      <div class="card py-4 card-item" @click="acao = 'troca'">
        <div class="card-body">
          <i class="fas fa-unlock-alt"></i> Trocar senha
        </div>
      </div>
      <div class="card py-4 card-item" @click="$emit('logout')">
        <div class="card-body">
          <i class="fas fa-sign-out-alt"></i> Sair da sessão
        </div>
      </div>
    </div>
    <Permissao v-if="acao == 'permissoes'"> </Permissao>
    <Senha v-if="acao == 'troca'"> </Senha>
  </div>
</template>

<script>
import Permissao from "../conta/Permissao.vue";
import Embarcacao from "../conta/Embarcacao.vue";
import Pendencia from "../conta/Pendencia.vue";
import Cobranca from "../conta/Cobranca.vue";
import Senha from "../conta/Senha.vue";
export default {
  components: {
    Permissao,
    Embarcacao,
    Pendencia,
    Cobranca,
    Senha,
  },
  data() {
    return {
      acao: "lista",
      embarcacoes: [],
      buscando: false,
      permissoes: [],
      cpfcnpj: "12345678912",
      embarcacao: null,
      permitindo: false,
      tirandoPermissao: false,
      senhaAtual: "password",
      novaSenha: "12345678",
      repitaNovaSenha: "12345678",
      trocando: false,
    };
  },
  methods: {
    back() {
      if (this.acao == "lista") {
        this.$emit("main");
        return;
      }
      this.acao = "lista";
    },
    atualizar() {},
  },
  watch: {
    acao(acao) {
      if (acao == "troca") {
        // this.senhaAtual = "";
        // this.novaSenha = "";
        // this.repitaNovaSenha = "";
      }
    },
  },
};
</script>