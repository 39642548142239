<template>
  <Layout
    @acao="lowerMenuAction($event)"
    ref="layout"
    @back="back"
  >
    <template slot="body">
      <div class="h-100">
        <Main
          ref="main"
          v-show="acao == 'Main'"
          @servico="
            acao = 'Solicitacao';
            $refs.layout.acao = 'Solicitacao';
          "
          @conta="
            acao = 'Conta';
            $refs.layout.acao = 'Conta';
          "
          @historico="
            acao = 'Lista';
            $refs.layout.acao = 'Lista';
          "
          @embarcacao="
            acao = 'Conta';
            $refs.conta.acao = 'embarcacoes';
          "
          @pendencia="
            acao = 'Conta';
            $refs.conta.acao = 'pendencias';
          "
          @cobranca="
            acao = 'Conta';
            $refs.conta.acao = 'cobrancas';
          "
          @back="$refs.layout.back = $event"
        />
        <Solicitacao
          ref="solicitacao"
          @main="
            acao = 'Main';
            $refs.layout.acao = 'Main';
            $refs.main.acao = null;
          "
          v-if="acao == 'Solicitacao'"
        />
        <Lista
          ref="lista"
          @back="$refs.layout.back = $event"
          v-if="acao == 'Lista'"
          @main="
            acao = 'Main';
            $refs.layout.acao = 'Main';
          "
        />
        <Conta
          ref="conta"
          @logout="logout"
          v-show="acao == 'Conta'"
          @main="
            acao = 'Main';
            $refs.layout.acao = 'Main';
          "
          @back="$refs.layout.back = $event"
        />
      </div>
    </template>
  </Layout>
</template>

<script>
import Lista from "./home/Lista.vue";
import Main from "./home/Main.vue";
import Solicitacao from "./home/Solicitacao.vue";
import Conta from "./home/Conta.vue";
import Layout from "./Layout.vue";

export default {
  components: {
    Lista,
    Solicitacao,
    Layout,
    Main,
    Conta,
  },
  data() {
    return {
      acao: "Main",
    };
  },
  methods: {
    lowerMenuAction(event) {
      if (this.acao == event) {
        if (event == 'Main') {
          this.$refs.main.acao = null;
        } else {
          this.refresh();
        }
      } else {
        this.acao = event;
      }
    },
    refresh() {
      var a = this.acao;
      this.acao = "refresh";
      setTimeout(() => {
        this.acao = a;
      }, 100);
    },
    back() {
      if (this.acao == "Conta") {
        this.$refs.conta.back();
      }
      if (this.acao == "Lista") {
        this.$refs.lista.back();
      }
      if (this.acao == "Main") {
        this.$refs.main.back();
      }
      if (this.acao == "Solicitacao") {
        this.$refs.solicitacao.back();
      }
    },
    logout() {
      api("/logout", "post", { email: this.login, password: this.senha });
      localStorage.removeItem("token");
      this.$emit("login");
    },
  },
  mounted() {
    // swiper.slideTo(1, 300, false);
  },
  watch: {
    acao(acao) {
      this.$refs.layout.back = false;
      if (acao != "Main") {
        this.$refs.layout.back = true;
      }
      // if (acao == "Main") {
      //   this.swiper.slideTo(0, 1, false);
      //   this.$refs.layout.acao = "Main";
      //   this.$refs.main.atualizar();
      //   this.$refs.conta.acao = "lista";
      // }

      // if (acao == "Solicitacao") {
      //   this.swiper.slideTo(1, 1, false);
      //   this.$refs.layout.acao = "Solicitacao";
      //   this.$refs.solicitacao.atualizar();
      //   this.$refs.conta.acao = "lista";
      // }
      // if (acao == "Lista") {
      //   this.swiper.slideTo(2, 1, false);
      //   this.$refs.layout.acao = "Lista";
      //   this.$refs.lista.atualizar();
      //   this.$refs.conta.acao = "lista";
      // }
      // if (acao == "Conta") {
      //   this.swiper.slideTo(3, 1, false);
      //   this.$refs.layout.acao = "Conta";
      //   this.$refs.conta.atualizar();
      // }
    },
  },
};
</script>