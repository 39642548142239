<template>
  <div :class="'notificacao' + (timer ? ' show' : '')">
    <span :style="'background-color: ' + cor" v-html="texto"></span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      texto: "Login ou senha incorretos",
      timer: null,
      cor: "#fff",
    };
  },
  methods: {
    show(texto, cor = "#fff", tempo = 6000) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
        setTimeout(() => {
          this.texto = texto;
          this.cor = cor;
          this.timer = setTimeout(() => {
            this.timer = null;
          }, tempo);
        }, 100);
      } else {
        this.texto = texto;
        this.cor = cor;
        this.timer = setTimeout(() => {
          this.timer = null;
        }, tempo);
      }
    },
  },
};
</script>