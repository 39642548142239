<template>
  <div>
    <h4 class="text-center" style="color: #003760">Cobranças</h4>
    <select
      id="ano"
      name="ano"
      class="form-select"
      style="margin-top: 15px; margin-bottom: 15px; width: 100%; color: black;"
      v-model="parametros.ano"
    >
      <option :value="a" v-for="(a, i) in anos" :key="i">
        {{ a }}
      </option>
    </select>
    <Busca ref="busca" url="/cobrancas" metodo="get" :parametros="parametros" @dados="dados">
      <template slot="res">
        <div ref="cobrancasContainer">
          <div v-for="(mes, j) in cobrancas.meses" :key="j" class="card py-4">
            <div class="card-body">
              <h4 class="text-center" style="color: #003760">{{ mes.nome }}</h4>
              <table class="w-100">
                <tr v-for="(cobranca, k) in mes.cobrancas" :key="k">
                  <td>
                    <div class="mt-3">
                      <a :href="formatFilePath(cobranca)" target="_blank">
                        {{ formatFileName(cobranca) }}
                      </a>
                    </div>
                  </td>
                  <!--<td style="width: calc(100% - 100px);">
                    <div :style="'width: ' + (width - 150) + 'px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;'">
                      <a :href="formatFilePath(cobranca)" target="_blank">{{ formatFileName(cobranca) }}</a>
                    </div>
                  </td>
                  <td style="width: 100px;" class="text-end">
                    <button type="button" class="btn btn-primary" @click="visualizar(cobranca)"><i class="fas fa-search"></i></button>
                    <button type="button" class="btn btn-primary" @click="downloadPdfFile(cobranca)"><i class="fa fa-download"></i></button>
                  </td>-->
                </tr>
              </table>
              <div class="mt-5" v-if="mes.cobrancas == null || mes.cobrancas.length == 0">
                <h5 class="text-center" style="color: #003760">
                  Nenhuma cobrança
                </h5>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Busca>
  </div>
</template>

<script>
import Busca from "../Busca.vue";
export default {
  components: {
    Busca,
  },
  data() {
    return {
      cobrancas: [],
      cobranca: null,
      anos: [],
      parametros: {
        ano: null
      },
      width: 200
    }
  },
  watch: {
    cobrancas() {
      const element = this.$refs.cobrancasContainer;

      if (element) {
        this.width = element.offsetWidth;
      }
    }
  },
  mounted() {
    const anoAtual = new Date().getFullYear();

    for (var i = anoAtual; i >= 2000; i--) {
      this.anos.push(i);
    }

    this.parametros.ano = anoAtual;
  },
  methods: {
    dados(e) {
      this.cobrancas = e;
    },
    formatFileName(filePath) {
      return filePath.split("/").pop();
    },
    formatFilePath(filePath) {
      return getApiUrl() + '/storage/' + filePath;
    },
  }
};
</script>