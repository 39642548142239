<template>
  <Busca url="/embarcacao/minhas" metodo="get" @dados="dados">
    <template slot="res">
      <div>
        <h4 class="text-center" style="color: #003760">Selecione uma embarcação</h4>
        <div
          class="card py-4 card-item"
          v-for="(e, i) in embarcacoes"
          :key="i"
          @click="$emit('embarcacao', e)"
        >
          <div class="card-body">
            <i class="fas fa-ship"></i> <span>{{ e.nome }}</span>
            <div v-if="e.local" class="px-3">
              <span
                >Local:
                {{ e.local.nome }}</span
              >
            </div>
          </div>
        </div>
        <div class="mt-5" v-if="embarcacoes.length == 0">
          <h5 class="text-center" style="color: #003760">
            Nenhuma embarcação encontrada
          </h5>
        </div>
      </div>
    </template>
  </Busca>
</template>

<script>
import Busca from "../Busca.vue";
export default {
  components: {
    Busca,
  },
  data() {
    return {
      embarcacoes: [],
    };
  },
  methods: {
    dados(e) {
      this.embarcacoes = e;
    },
  },
};
</script>