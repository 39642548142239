<template>
  <Busca url="/pendencias" metodo="get" @dados="dados">
    <template slot="res">
      <div>
        <h4 class="text-center" style="color: #003760">Pendências</h4>
        <div
          class="card py-4 card-item"
          v-for="(e, i) in pendencias"
          :key="i"
          @click="embarcacao = i"
        >
          <div class="card-body">
            {{e}}
          </div>
        </div>
        <div class="mt-5" v-if="pendencias.length == 0">
          <h5 class="text-center" style="color: #003760">
            Nenhuma pendência
          </h5>
        </div>
      </div>
    </template>
  </Busca>
</template>

<script>
import Busca from "../Busca.vue";
export default {
  components: {
    Busca,
  },
  data() {
    return {
      pendencias: [],
    };
  },
  methods: {
    dados(e) {
      this.pendencias = e;
    },
  },
};
</script>