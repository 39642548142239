<template>
  <div class="h-100 container d-flex fundo">
    <div style="margin: auto">
      <div
        class=""
        style="border-radius: 5px; padding-bottom: 20px; margin-top: -100px"
      >
        <div class="d-flex">
          <img
            src="/img/logo1.png"
            alt=""
            class="logo"
            onload="this.classList.add('show')"
          />
        </div>
        <!-- <div class="input-group">
      <div class="form-group label-floating is-empty">
        <label class="control-label">Login</label>
        <input name="name" type="text" class="form-control" v-model="login" />
        <span class="material-input"></span>
      </div>
    </div> -->

        <div class="container login-body">
          <div class="input-group mb-3 flex-nowrap">
            <div class="input-group-prepend">
              <span class="input-group-text px-1">
                <i class="material-icons" style="color: #003760">email</i>
              </span>
            </div>
            <input
              type="text"
              class="form-control"
              placeholder="Email/CPF"
              v-model="login"
              @keyup.enter="$refs.password.focus()"
            />
          </div>
          <div class="input-group flex-nowrap mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text px-1">
                <i class="material-icons" style="color: #003760"
                  >lock_outline</i
                >
              </span>
            </div>
            <input
              type="password"
              class="form-control"
              placeholder="Senha"
              v-model="senha"
              ref="password"
              @keyup.enter="
                $refs.password.blur();
                $refs.enter.click();
              "
            />
          </div>
          <div class="px-5 mt-3">
            <button
              type="button"
              class="btn btn-success btn-block w-100"
              style="max-height: 38px; overflow: hidden"
              @click="logar"
              ref="enter"
              :disabled="tentando"
            >
              <div
                class="lds-ellipsis"
                style="margin-top: -27px"
                v-if="tentando"
              >
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div v-else>Entrar</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      login: "",
      senha: "",
      tentando: false,
    };
  },
  methods: {
    logar() {
      this.tentando = true;
      //  this.$emit("home");
      //debugger
      api(
        "/login",
        "post",
        { email: this.login, password: this.senha },
        this.sucesso,
        this.fail,
        this.erro
      );
    },
    sucesso(e) {
      this.tentando = false;
      token = e.request.token;
      localStorage.setItem("usuario", JSON.stringify({ nome: e.request.name }));
      localStorage.setItem("token", token);
      this.$emit("home");
    },
    fail(e) {
      this.tentando = false;
      this.$parent.notificaoErro("Login ou senha incorretos!");
    },
    erro(e) {
      this.tentando = false;
      this.$parent.notificaoErro("Falha ao tentar autenticar!");
    },
  },
};
</script>