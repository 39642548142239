<template>
  <div class="card" @click="$emit('acao')">
    <div class="card-header">
      <div class="d-flex">
        <span>{{ servico.embarcacao.nome }}</span>
        <i class="fa fa-ship" style="margin-left: auto"></i>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 mb-2">
          <div>Status: {{ status }}</div>
        </div>
        <div class="col-12 mb-2">
          <div>Data de Solicitação: {{ new Date(atual.dt_criacao).toLocaleString() }}</div>
        </div>
        <div class="col-12 mb-2">
          <div>Horário de Início: {{ new Date(atual.horario_previsto).toLocaleString() }}</div>
        </div>
        <div class="col-12 mb-2">
          <div>Horário de Conclusão: {{ new Date(atual.horario_termino).toLocaleString() }}</div>
        </div>
        <div class="col-12 mb-2">
          <div>Solicitante: {{ atual.usuario ? atual.usuario.name : 'Automático' }}</div>
        </div>
        <div class="col-12 mb-2">
          <h4 style="font-weight: 400" class="text-center">
            Serviços
          </h4>
        </div>
        <div class="row">
          <div v-if="abastecer" :class="'col-6 text-center mb-3 ' + abastecerStatus">
            <div class="item-lista">
              <i class="fas fa-gas-pump fa-2x"></i>
            </div>
            <span>{{ quantidadeAbastecimento }}</span>
          </div>
          <div v-if="aguaDoce" :class="'col-6 text-center mb-3 ' + aguaDoceStatus">
            <div class="item-lista">
              <i class="fas fa-glass-martini fa-2x"></i>
            </div>
            <span>Água doce</span>
          </div>
          <div v-if="cleanBoat" :class="'col-6 text-center mb-3 ' + cleanBoatStatus">
            <div class="item-lista">
              <i class="fas fa-shower fa-2x"></i>
            </div>
            <span>Limpeza do barco</span>
          </div>
          <div v-if="oleo2t" :class="'col-6 text-center mb-3 ' + oleo2tStatus">
            <div class="item-lista">
              <i class="fas fa-oil-can fa-2x"></i>
            </div>
            <span>Óleo 2T</span>
          </div>
          <div v-if="giroMotor" :class="'col-6 text-center mb-3 ' + giroMotorStatus">
            <div class="item-lista">
              <i class="fas fa-cog fa-2x"></i>
            </div>
            <span>Giro de motor</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["servico", "horario"],
  computed: {
    quantidadeAbastecimento() {
      if (this.abastecer) {
        if (this.abastecer.abastecer_completo === "T") {
          return "Completo"
        } else if (this.abastecer.litro) {
          return this.abastecer.litro + " L";
        } else if (this.abastecer.valor) {
          return "R$ " + this.abastecer.valor;
        }
      }

      return null;
    },
    cleanBoatStatus() {
      if (this.limparBarco) {
        if (this.limparBarco.status_servico_id == 2) {
          return 'text-success';
        } else if (this.limparBarco.status_servico_id == 3) {
          return 'text-danger';
        }
      }

      return '';
    },
    aguaDoceStatus() {
      if (this.aguaDoce) {
        if (this.aguaDoce.status_servico_id == 2) {
          return 'text-success';
        } else if (this.aguaDoce.status_servico_id == 3) {
          return 'text-danger';
        }
      }

      return '';
    },
    abastecerStatus() {
      if (this.abastecer) {
        if (this.abastecer.status_servico_id == 2) {
          return 'text-success';
        } else if (this.abastecer.status_servico_id == 3) {
          return 'text-danger';
        }
      }

      return '';
    },
    oleo2tStatus() {
      if (this.oleo2t) {
        if (this.oleo2t.status_servico_id == 2) {
          return 'text-success';
        } else if (this.oleo2t.status_servico_id == 3) {
          return 'text-danger';
        }
      }

      return '';
    },
    giroMotorStatus() {
      if (this.giroMotor) {
        if (this.giroMotor.status_servico_id == 2) {
          return 'text-success';
        } else if (this.giroMotor.status_servico_id == 3) {
          return 'text-danger';
        }
      }

      return '';
    },
    cleanBoat() {
      for (const key in this.servico.servicos) {
        if (this.servico.servicos[key].tipo_servico_id == 1) {
          return this.servico.servicos[key];
        }
      }
      return null;
    },
    aguaDoce() {
      for (const key in this.servico.servicos) {
        if (this.servico.servicos[key].tipo_servico_id == 3) {
          return this.servico.servicos[key];
        }
      }
      return null;
    },
    abastecer() {
      for (const key in this.servico.servicos) {
        if (this.servico.servicos[key].tipo_servico_id == 4) {
          return this.servico.servicos[key];
        }
      }
      return null;
    },
    oleo2t() {
      for (const key in this.servico.servicos) {
        if (this.servico.servicos[key].tipo_servico_id == 6) {
          return this.servico.servicos[key];
        }
      }
      return null;
    },
    giroMotor() {
      for (const key in this.servico.servicos) {
        if (this.servico.servicos[key].tipo_servico_id == 7) {
          return this.servico.servicos[key];
        }
      }
      return null;
    },
    atual() {
      var subida = null;
      var descida = null;
      for (const key in this.servico.servicos) {
        if (this.servico.servicos[key].tipo_servico_id == 2) {
          if (this.servico.servicos[key].ativo == "T") {
            return this.servico.servicos[key];
          } else {
            subida = this.servico.servicos[key];
          }
        } else if (this.servico.servicos[key].tipo_servico_id == 5) {
          if (this.servico.servicos[key].ativo == "T") {
            return this.servico.servicos[key];
          } else {
            descida = this.servico.servicos[key];
          }
        }
      }

      return descida ? descida : subida;
    },
    status() {
      if (this.atual.status_servico_id == 1) {
        return "Aguardando";
      }
      if (this.atual.status_servico_id == 2) {
        return "Concluído";
      }
      if (this.atual.status_servico_id == 3) {
        return "Cancelado";
      }
      return "?";
    },
  },
};
</script>