<template>
  <Busca url="/embarcacao/minhas" metodo="get" @dados="dados">
    <template slot="res">
      <div>
        <h4 class="text-center" style="color: #003760">Embarcações</h4>
        <div
          class="card py-4 card-item"
          v-for="(e, i) in embarcacoes"
          :key="i"
          @click="embarcacao = i"
        >
          <div class="card-body">
            <i class="fas fa-ship"></i> <span>{{ e.nome }}</span>
            <div>
              <span
                >Tipo combustivel:
                {{ e.tipo_combustivel == "D" ? "Diesel" : "Gasolina" }}</span
              >
            </div>
            <div>
              <span>Local: {{ e.local ? e.local.nome : "-" }}</span>
            </div>
            <div v-if="e.embarcacao_status_id != 1" class="mt-2 text-danger">
              <i class="fas fa-ban"></i>
              <span> Embaração bloqueada</span>
              <div class="px-3">
                <small class="d-block" v-if="e.embarcacao_status_id == null"
                  >Motivo não especificado</small
                >
                <small class="d-block" v-else>{{ e.status.nome }}</small>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5" v-if="embarcacoes.length == 0">
          <h5 class="text-center" style="color: #003760">
            Nenhuma embarcação encontrada
          </h5>
        </div>
      </div>
    </template>
  </Busca>
</template>

<script>
import Busca from "../Busca.vue";
export default {
  components: {
    Busca,
  },
  data() {
    return {
      embarcacoes: [],
    };
  },
  methods: {
    dados(e) {
      this.embarcacoes = e;
    },
  },
};
</script>