<template>
  <Busca :url="'/historico-de-servicos?embarcacao=' + embarcacao.id" metodo="get" @dados="dados">
    <template slot="res">
      <div class="h-100">
        <div class="pt-5" v-if="servicos.length == 0">
          <h5 class="text-center" style="color: #003760">
            Nenhum serviço solicitado
          </h5>
        </div>
        <Card
          v-for="(s, i) in servicos"
          :key="i"
          :servico="s"
        ></Card>
      </div>
    </template>
  </Busca>
</template>
 

<script>
import Busca from "../Busca.vue";
import Card from "./Card.vue";
export default {
  components: {
    Busca,
    Card,
  },
  props: ['embarcacao'],
  data() {
    return {
      servicos: [],
    };
  },
  methods: {
    dados(e) {
      this.servicos = e;
    },
  },
  mounted() {},
  watch: {},
};
</script>