<template>
  <div class="container">
    <div v-if="acao == null">
      <div class="text-center mb-3" v-if="usuario">
        <h4 style="color: #003760">{{ usuario.nome }}</h4>
      </div>
      <div class="row my-2">
        <div class="col-6">
          <div class="card-home" @click="acao = 'embarcacoes'">
            <i class="fas fa-ship"></i> Embarcação
          </div>
        </div>
        <div class="col-6">
          <div class="card-home" @click="$emit('servico')">
            <i class="fas fa-cog"></i> Serviço
          </div>
        </div>
        <div class="col-6 mt-4">
          <div class="card-home" @click="$emit('historico')">
            <i class="fas fa-history"></i> Histórico
          </div>
        </div>
        <div class="col-6 mt-4">
          <div class="card-home" @click="acao = 'pendencias'">
            <i class="fas fa-exclamation-triangle"></i> Pendências
          </div>
        </div>
        <div class="col-6 mt-4">
          <div class="card-home" @click="acao = 'cobrancas'">
            <i class="fas fa-dollar-sign"></i> Cobranças
          </div>
        </div>
        <div class="col-6 mt-4">
          <div class="card-home" @click="$emit('conta')">
            <i class="fas fa-user"></i> Conta
          </div>
        </div>
      </div>
    </div>
    <Embarcacao v-if="acao == 'embarcacoes'"> </Embarcacao>
    <Pendencia v-if="acao == 'pendencias'"> </Pendencia>
    <Cobranca v-if="acao == 'cobrancas'"> </Cobranca>
  </div>
</template>

<script>
import Embarcacao from "../conta/Embarcacao.vue";
import Pendencia from "../conta/Pendencia.vue";
import Cobranca from "../conta/Cobranca.vue";
export default {
  components: {
    Embarcacao,
    Pendencia,
    Cobranca,
  },
  data() {
    return {
      usuario: null,
      acao: null,
    };
  },
  methods: {
    back() {
      this.acao = null;
    },
    atualizar() {},
  },
  created() {
    var us = localStorage.getItem("usuario");
    if (us) {
      this.usuario = JSON.parse(us);
    }
  },
  watch: {
    acao(acao) {
      if (acao != null) {
        this.$emit("back", true);
      } else {
        this.$emit("back", false);
      }
    },
  },
};
</script>