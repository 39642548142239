<template>
  <Busca url="/embarcacao/todas" metodo="get" @dados="dados">
    <template slot="res">
      <div class="px-3 h-100">
        <div class="wizard-container h-100">
          <div
            class="card wizard-card mb-0 h-100"
            data-color="blue"
            ref="wizard"
          >
            <div class="wizard-header py-2">
              <h4 class="wizard-title">Solicitar descida</h4>
            </div>
            <div class="wizard-navigation">
              <ul class="nav nav-pills">
                <li
                  class="active"
                  style="width: 25%; margin-top: 10px; margin-bottom: 10px"
                  @click="mover(0)"
                >
                  <a href="#embarcacao" data-toggle="tab" aria-expanded="true"
                    >Embarcação</a
                  >
                </li>
                <li
                  class=""
                  style="width: 25%; margin-top: 10px; margin-bottom: 10px"
                  @click="mover(1)"
                >
                  <a href="#plano" data-toggle="tab" aria-expanded="false"
                    >Plano</a
                  >
                </li>
                <li
                  class=""
                  style="width: 25%; margin-top: 10px; margin-bottom: 10px"
                  @click="mover(2)"
                >
                  <a href="#servico" data-toggle="tab" aria-expanded="false"
                    >Serviços</a
                  >
                </li>
                <li
                  style="width: 25%; margin-top: 10px; margin-bottom: 10px"
                  @click="mover(3)"
                >
                  <a href="#horario" data-toggle="tab" aria-expanded="false"
                    >Horário</a
                  >
                </li>
              </ul>
              <div
                class="moving-tab"
                style="
                  width: 118.333px;
                  transform: translate3d(-8px, 0px, 0px);
                  transition: transform 0s ease 0s;
                  margin-top: 10px;
                  margin-bottom: 10px;
                "
              >
                Embarcação
              </div>
            </div>

            <div class="tab-content my-4">
              <div
                :class="'tab-pane h-100' + (wizard.index == 0 ? ' active' : '')"
              >
                <h4 class="text-center">Para qual embarcação?</h4>
                <div class="list-group">
                  <a
                    v-for="(e, i) in embarcacoes"
                    href="javascript:void(0)"
                    :class="
                      'list-group-item list-group-item-action py-3' +
                      (e.embarcacao_status_id == 1
                        ? ''
                        : ' list-group-item-secondary') +
                      (embarcacao == i ? ' active' : '')
                    "
                    :key="i"
                    @click="
                      e.embarcacao_status_id == 1
                        ? (embarcacao = i)
                        : (embarcacao = null)
                    "
                  >
                    <div>
                      <i :class="e.tipo ? 'fa fa-motorcycle' : 'fa fa-ship'"></i
                      ><span class="mx-2">{{ e.nome }}</span>
                    </div>
                  </a>
                </div>
              </div>
              <div
                :class="'tab-pane h-100' + (wizard.index == 1 ? ' active' : '')"
              >
                <h4 class="text-center">Plano de Navegação</h4>
                <div class="mt-3 px-2">
                  <span><b>Retorno</b></span>
                  <input
                    ref="planoDataRegresso"
                    type="datetime-local"
                    class="form-control"
                    style="color: #000000"
                    v-model="planoDataRegresso"
                  />
                </div>
                <div class="mt-3 px-2">
                  <span><b>Destino</b></span>
                  <input
                    ref="planoDestino"
                    type="text"
                    class="form-control"
                    style="color: #000000"
                    v-model="planoDestino"
                  />
                </div>
                <div class="mt-3 px-2">
                  <span><b>Telefone de contato</b></span>
                  <input
                    ref="planoTelefoneContato"
                    type="text"
                    class="form-control"
                    style="color: #000000"
                    placeholder="(99) 99999-9999"
                    v-model="planoTelefoneContato"
                  />
                </div>
                <div class="mt-3 px-2">
                  <span>
                    <b>A embarcação foi vistoriada pelo comandante e está em boas condições de navegar. Assumo total responsabilidade pelas informações acima citadas.</b>
                  </span>
                  <div class="form-check checkbox-lg">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="planoEmbarcacaoVistoriada"
                      id="planoEmbarcacaoVistoriada"
                      ref="planoEmbarcacaoVistoriada"
                      style="color: #000000"
                    />
                    <label
                      class="form-check-label"
                      for="planoEmbarcacaoVistoriada"
                      style="color: #000000"
                    >
                      Concordo
                    </label>
                  </div>
                </div>
              </div>
              <div
                :class="'tab-pane h-100' + (wizard.index == 2 ? ' active' : '')"
              >
                <h4 class="text-center">Quais serviços?</h4>
                <ul class="list-group">
                  <li
                    class="list-group-item"
                    @click="
                      !abastecer
                        ? (abastecer = !abastecer)
                        : (abastecer = abastecer)
                    "
                  >
                    <div
                      @click="
                        abastecer = !abastecer;
                        $event.stopPropagation();
                      "
                      class="row"
                    >
                      <div class="col-8 py-2">
                        <i class="fa fa-gas-pump"></i
                        ><span class="mx-2">Abastecimento</span>
                      </div>
                      <div class="col-4 text-right">
                        <div
                          style="text-align: right; font-size: 26px"
                          v-if="abastecer"
                        >
                          <i class="fa fa-check text-primary"></i>
                        </div>
                      </div>
                    </div>
                    <div :class="'sub-menu' + (abastecer ? ' show' : '')">
                      <div class="mt-3">
                        <div class="row">
                          <div
                            class="col-4 text-center px-0"
                            @click="tipoAbastecer = 'dinheiro'"
                          >
                            <span
                              class="mx-2"
                              :style="
                                tipoAbastecer == 'dinheiro'
                                  ? 'color: #003760; font-weight: bold; font-size: 18px'
                                  : 'color: #666;'
                              "
                              >Dinheiro</span
                            >
                          </div>
                          <div
                            class="col-3 text-center px-0"
                            @click="tipoAbastecer = 'litro'"
                          >
                            <span
                              class="mx-2"
                              :style="
                                tipoAbastecer == 'litro'
                                  ? 'color: #003760; font-weight: bold; font-size: 18px'
                                  : 'color: #666;'
                              "
                              >Litros</span
                            >
                          </div>
                          <div
                            class="col-5 text-center px-0"
                            @click="tipoAbastecer = 'completo'"
                          >
                            <span
                              class="mx-2"
                              :style="
                                tipoAbastecer == 'completo'
                                  ? 'color: #003760; font-weight: bold; font-size: 18px'
                                  : 'color: #666;'
                              "
                              >Completo</span
                            >
                          </div>
                        </div>
                        <input
                          v-if="
                            tipoAbastecer != null && tipoAbastecer != 'completo'
                          "
                          type="number"
                          class="form-control mt-3"
                          style="color: #000000"
                          :placeholder="
                            tipoAbastecer == 'dinheiro' ? 'Dinheiro' : 'Litros'
                          "
                          ref="valor"
                          @change="atualizarValor()"
                          @keyup.enter="$refs.valor.blur()"
                          v-model="valor"
                        />
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item" @click="aguaDoce = !aguaDoce">
                    <div class="row">
                      <div class="col-8 py-2">
                        <i class="fa fa-glass-martini-alt"></i
                        ><span class="mx-2">Água doce</span>
                      </div>
                      <div class="col-4 text-right">
                        <div
                          style="text-align: right; font-size: 26px"
                          v-if="aguaDoce"
                        >
                          <i class="fa fa-check text-primary"></i>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li
                    class="list-group-item"
                    @click="oleo = !oleo"
                    v-if="embarcacao != null && embarcacoes[embarcacao].oleo2t == 'T'"
                  >
                    <div class="row">
                      <div class="col-8 py-2">
                        <i class="fa fa-oil-can"></i
                        ><span class="mx-2">Óleo 2T</span>
                      </div>

                      <div class="col-4 text-right">
                        <div
                          style="text-align: right; font-size: 26px"
                          v-if="oleo"
                        >
                          <i class="fa fa-check text-primary"></i>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div
                :class="'tab-pane h-100' + (wizard.index == 3 ? ' active' : '')"
              >
                <h4 class="text-center">Para qual data?</h4>
                <div class="px-2 mt-2">
                  <span><b>Data</b></span>
                  <input
                    ref="data"
                    type="datetime-local"
                    class="form-control"
                    style="color: #000000"
                    v-model="data"
                  />
                </div>
                <div class="mt-3 px-2">
                  <span><b>Observação (Opcional)</b></span>
                  <textarea
                    rows="5"
                    class="w-100"
                    v-model="observacao"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="wizard-footer mb-2 text-primary">
              <div
                class="pull-left"
                @click="mover(wizard.index - 1)"
                v-show="wizard.index > 0"
              >
                <i class="fas fa-chevron-left"></i>
                <span style="margin-left: 5px">Voltar</span>
              </div>
              <div
                class="pull-right"
                @click="mover(wizard.index + 1)"
                v-show="wizard.index < 3"
              >
                <span style="margin-right: 5px">Próximo</span>
                <i class="fas fa-chevron-right"> </i>
              </div>
              <div
                class="pull-right"
                @click="finalizar"
                v-show="wizard.index == 3 && embarcacao != null && data != ''"
              >
                <span style="margin-right: 5px; backgroud-color: blue"
                  >Finalizar</span
                >
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          ref="modal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          data-keyboard="false"
          data-backdrop="static"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Solicitação de descida</h5>
              </div>
              <div class="modal-body">
                <div v-show="resumo == 1">
                  <div class="mt-2">
                    <span><b>Embarcação</b></span>
                    <div class="px-2" v-if="embarcacao != null">
                      <i class="fas fa-ship" style="width: 25px"></i>
                      <span class="mx-2">{{
                        embarcacoes[embarcacao].nome
                      }}</span>
                    </div>
                  </div>
                  <div class="mt-2">
                    <span><b>Serviços</b></span>
                    <div
                      class="px-2"
                      v-show="
                        !oleo &&
                        !aguaDoce &&
                        !(
                          abastecer &&
                          (tipoAbastecer == 'completo' ||
                            (tipoAbastecer && valor != ''))
                        )
                      "
                    >
                      <span class="mx-2">Nenhum</span>
                    </div>
                    <div class="px-2" v-show="oleo">
                      <i class="fa fa-oil-can" style="width: 25px"></i
                      ><span class="mx-2">Ólho 2T</span>
                    </div>
                    <div class="px-2" v-show="aguaDoce">
                      <i class="fa fa-glass-martini-alt" style="width: 25px"></i
                      ><span class="mx-2">Água doce</span>
                    </div>
                    <div
                      class="px-2"
                      v-show="
                        abastecer &&
                        (tipoAbastecer == 'completo' ||
                          (tipoAbastecer && valor != ''))
                      "
                    >
                      <i class="fa fa-gas-pump" style="width: 25px"></i
                      ><span class="mx-2"
                        >Abastecer
                        {{
                          tipoAbastecer == "dinheiro"
                            ? "R$ " +
                              parseFloat(valor).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : tipoAbastecer == "completo"
                            ? "Completo"
                            : parseFloat(valor).toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                              }) + " litros"
                        }}</span
                      >
                    </div>
                  </div>
                  <div class="mt-2">
                    <span><b>Destino</b></span>
                    <div class="px-2">
                      <i class="fa fa-map" style="width: 25px" aria-hidden="true"></i>
                      <span class="mx-2" style="white-space: pre">{{ planoDestino }}</span>
                    </div>
                  </div>
                  <div class="mt-2" v-if="observacao && observacao != ''">
                    <span><b>Observação</b></span>
                    <div
                      class="px-2"
                      v-if="observacao != null && observacao != ''"
                    >
                      <i class="fa fa-info" style="width: 25px" aria-hidden="true"></i>
                      <span class="mx-2" style="white-space: pre">{{ observacao }}</span>
                    </div>
                  </div>
                  <div class="mt-2">
                    <span><b>Horário</b></span>
                    <div class="px-2">
                      <i class="fa fa-clock" style="width: 25px"></i
                      ><span class="mx-2">{{
                        new Date(data).toLocaleString()
                      }}</span>
                    </div>
                  </div>
                  <div class="mt-2">
                    <span><b>Retorno</b></span>
                    <div class="px-2">
                      <i class="fa fa-clock" style="width: 25px"></i
                      ><span class="mx-2">{{
                        new Date(planoDataRegresso).toLocaleString()
                      }}</span>
                    </div>
                  </div>
                </div>
                <div v-if="resumo == 2">
                  <h5 class="text-center">Solicitando</h5>
                  <div style="display: flex">
                    <div class="lds-ellipsis" style="margin: auto">
                      <div style="background: #003760"></div>
                      <div style="background: #003760"></div>
                      <div style="background: #003760"></div>
                      <div style="background: #003760"></div>
                    </div>
                  </div>
                </div>
                <div v-if="resumo == 3">
                  <h5 class="text-center text-success">Serviço agendado</h5>
                  <h5 v-if="retornoForaDoHorario" class="text-center text-warning">Atenção, horário de retorno previsto informado está fora do horário de operação da Marina. Barco ficará em Poita. Serviço de bote 24 Horas.</h5>
                  <div style="display: flex" class="mt-3">
                    <button
                      class="btn btn-primary"
                      style="margin: auto"
                      @click="
                        cancelar();
                        $emit('main');
                      "
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
              <div class="modal-footer" v-if="resumo == 1">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                  @click="cancelar"
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="solicitar"
                  :disabled="data == ''"
                >
                  Solicitar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Busca>
</template>
 

<script>
import Busca from "../Busca.vue";
export default {
  components: {
    Busca,
  },
  data() {
    return {
      planoEmbarcacaoVistoriada: false,
      planoDataRegresso: "",
      planoDestino: "",
      planoTelefoneContato: "",
      embarcacoes: [],
      abastecer: false,
      aguaDoce: false,
      oleo: false,
      tipoAbastecer: null,
      embarcacao: null,
      wizard: { index: 0 },
      wizardInit: false,
      valor: "",
      buscandoEmbarcacao: true,
      tipo: null,
      resumo: 1,
      status: null,
      data: "",
      observacao: "",
      proximoHorario: null,
      mesmoDia: false,
      retornoForaDoHorario: false,
    };
  },
  methods: {
    back() {
      this.$emit("main");
    },
    finalizar() {
      this.proximoHorario = null;

      this.resumo = 1;
      $(this.$refs.modal).modal({
        backdrop: "static",
        keyboard: false,
      });
      $(this.$refs.modal).appendTo("body").modal("show");
    },
    solicitar() {
      this.resumo = 2;
      this.$forceUpdate();

      api(
        "/solicitar-servico",
        "post",
        {
          embarcacao: this.embarcacoes[this.embarcacao].id,
          abastecer: this.abastecer,
          agua_doce: this.aguaDoce,
          oleo: this.oleo,
          tipo_abastecer: this.tipoAbastecer,
          valor: this.valor != "" ? parseFloat(this.valor) : null,
          data: this.data,
          observacao: this.observacao == "" ? null : this.observacao,
          tipo: this.tipo,
          data_saida: this.data,
          data_regresso: this.planoDataRegresso,
          destino: this.planoDestino,
          embarcacao_vistoriada: this.planoEmbarcacaoVistoriada != false ? "SIM" : "NAO",
          telefone_contato: this.planoTelefoneContato
        },
        (e) => {
          if (!e.success) {
            this.cancelar();
            this.$parent.$parent.$parent.notificaoErro(e.message);
            return;
          } else {
            this.resumo = 3;
            this.retornoForaDoHorario = e.retornoForaDoHorario == true ? true : false;
            this.embarcacao = null;
            this.abastecer = false;
            this.agua_doce = false;
            this.oleo = false;
            this.tipo_abastecer = null;
            this.valor = "";
            this.tipo = null;
            this.planoDataRegresso = "";
            this.planoDestino = "";
            this.planoTelefoneContato = "";
          }
        },
        (e) => {
          if (!e.success) {
            this.cancelar();
          }
        },
        (e) => {
          if (!e.success) {
            this.cancelar();
            if (e.responseJSON.errors) {
              var errors = "";

              for (var field in e.responseJSON.errors) {
                for (var error in e.responseJSON.errors[field]) {
                  errors = errors + e.responseJSON.errors[field][error] + ".</br>";
                }
              }

              errors = errors.slice(0, -5);

              this.$parent.$parent.$parent.notificaoErro(errors);
            } else {
              this.$parent.$parent.$parent.notificaoErro(e.message);
            }
          }
        }
      );
    },
    cancelar() {
      $(this.$refs.modal).modal("hide");
    },
    mover(i) {
      switch(i) {
        case 1:
          if (this.validarEmbarcacao()) {
            return;
          }
          break;
        case 2:
          if (this.validarEmbarcacao() || this.validarPlano()) {
            return;
          }
          break;
        case 3:
          if (this.validarEmbarcacao() || this.validarPlano() || this.validarServicos()) {
            return;
          }
          break;
        default:
          break;
      }

      this.wizard.mover(i);
    },
    validarEmbarcacao() {
      return this.embarcacao == null || this.embarcacoes[this.embarcacao].id == '' || this.embarcacoes[this.embarcacao].id == null
    },
    validarPlano() {
      if (this.planoDataRegresso == null || this.planoDataRegresso == '') {
        this.$refs.planoDataRegresso.focus();
        return true;
      }
      if (this.planoDestino == null || this.planoDestino == '') {
        this.$refs.planoDestino.focus();
        return true;
      }
      if (this.planoTelefoneContato == null || this.planoTelefoneContato == '') {
        this.$refs.planoTelefoneContato.focus();
        return true;
      }
      if (this.planoTelefoneContato.replace(/\D/g, '').length < 10) {
        this.$refs.planoTelefoneContato.focus();
        return true;
      }
      if (this.planoEmbarcacaoVistoriada != true) {
        this.$refs.planoEmbarcacaoVistoriada.focus();
        return true;
      }

      return false;
    },
    validarServicos() {
      if (this.abastecer == true) {
        if (this.tipoAbastecer == null || this.tipoAbastecer == '') {
          return true;
        } else if ((this.tipoAbastecer == "litro" || this.tipoAbastecer == "dinheiro") && (this.valor == null || this.valor == '')) {
          this.$refs.valor.focus();
          return true;
        }
      }

      return false;
    },
    atualizarValor() {
      if (this.valor == "") {
        return;
      }
      if (this.tipoAbastecer) {
        if (this.tipoAbastecer == "dinheiro") {
          this.valor = parseFloat(this.valor).toFixed(2);
        } else {
          if (this.valor.indexOf(".") != -1) {
            if (this.valor.split(".")[1] == 0) {
              this.valor = parseInt(this.valor) + "";
            } else {
              this.valor = parseFloat(this.valor).toFixed(1);
            }
          }
        }
      }
    },
    dados(e) {
      this.embarcacoes = e;
      if (!this.wizardInit) {
        this.wizardInit = true;
        setTimeout(() => {
          this.wizard = new Wizard(this.$refs.wizard);
        }, 100);
      }
    },
  },
  mounted() {
    var agora = new Date();
    agora.setMinutes(agora.getMinutes() + 10);
    agora = agora.toISOString();
    var ponto = agora.indexOf(".");
    this.data = agora.substring(0, ponto);
    this.planoDataRegresso = agora.substring(0, ponto);
  },
  watch: {
    embarcacao(embarcacao) {
      if (embarcacao == null) {
        this.oleo = false;
      } else {
        if (this.oleo) {
          if (this.embarcacoes[embarcacao].oleo2t != "T") {
            this.oleo = false;
          }
        }
      }
    },
    tipoAbastecer(t) {
      this.atualizarValor();
      setTimeout(() => {
        if (this.$refs.valor) {
          this.$refs.valor.focus();
        }
      }, 150);
    },
    valor(valor) {
      if (this.valor != "" && parseFloat(this.valor) < 0) {
        this.valor = "";
        return;
      }
      if (this.tipoAbastecer) {
        if (this.tipoAbastecer == "dinheiro") {
          if (this.valor.indexOf(".") != -1) {
            if (this.valor.split(".")[1].length > 2) {
              this.valor = parseFloat(this.valor).toFixed(2);
            }
          }
        } else {
          if (this.valor.indexOf(".") != -1) {
            if (this.valor.split(".")[1].length > 1) {
              this.valor = parseFloat(this.valor).toFixed(1);
            }
          }
        }
      }
    },
    planoTelefoneContato(telefone) {
      const telefoneNumerico = telefone.replace(/\D/g, '');

      if (telefoneNumerico.length < 3) {
        this.planoTelefoneContato = telefoneNumerico;
        return;
      } else if (telefoneNumerico.length < 7) {
        this.planoTelefoneContato = `(${telefoneNumerico.substring(0, 2)}) ${telefoneNumerico.substring(2, 11)}`;
        return;
      } else if (telefoneNumerico.length < 11) {
        this.planoTelefoneContato = `(${telefoneNumerico.substring(0, 2)}) ${telefoneNumerico.substring(2, 6)}-${telefoneNumerico.substring(6, 11)}`;
        return;
      } else {
        this.planoTelefoneContato = `(${telefoneNumerico.substring(0, 2)}) ${telefoneNumerico.substring(2, 7)}-${telefoneNumerico.substring(7, 11)}`;
        return;
      }
    }
  },
};
</script>