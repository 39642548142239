<template>
  <div class="h-100">
    <div style="display: flex" v-if="buscando">
      <div class="lds-ellipsis" style="margin: auto">
        <div style="background: #003760"></div>
        <div style="background: #003760"></div>
        <div style="background: #003760"></div>
        <div style="background: #003760"></div>
      </div>
    </div>
    <div v-else class="h-100">
      <slot name="res" v-if="!erro"> </slot>
      <div v-else class="d-flex h-100">
        <div  style="margin: auto; color: #003760" @click="buscar">
        <h4 style="">Recarregar</h4>
        <div style="display: flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="currentColor"
            class="bi bi-arrow-clockwise"
            viewBox="0 0 16 16"
            style="margin: auto"
          >
            <path
              fill-rule="evenodd"
              d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
            />
            <path
              d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"
            />
          </svg>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      buscando: true,
      erro: null,
    };
  },
  props: [
    "url",
    "metodo",
    "parametros",
    "buscaImediata"
  ],
  methods: {
    buscar() {
      this.buscando = true;
      this.erro = null;
      api(
        this.url,
        this.metodo,
        this.parametros,
        (e) => {
          this.$emit("dados", e);
          this.buscando = false;
        },
        (e) => {
          this.buscando = false;
          this.erro = e;
        },
        (e) => {
          this.buscando = false;
          this.erro = e;
        }
      );
    },
  },
  watch: {
    parametros: {
      handler(newVal, oldVal) {
        this.buscar();
      },
      deep: true
    }
  },
  created() {
    if (this.buscaImediata === false) {
      this.buscando = false;
    } else {
      this.buscar();
    }
  }
};
</script>