<template>
  <div class="app">
    <Login v-if="acao == 'login'" @home="acao = 'home'" />
    <Home v-if="acao == 'home'" @login="acao = 'login'" />
    <Notificacao ref="notificacao"></Notificacao>
  </div>
</template>

<script>
import Login from "./components/Login.vue";
import Home from "./components/Home.vue";
import Notificacao from "./components/Notificacao.vue";
export default {
  name: "App",
  components: {
    Login,
    Home,
    Notificacao,
  },
  data() {
    return {
      acao: token == null ? "login" : "home",
    };
  },
  methods: {
    notificaoSucesso(texto, tempo = 6000) {
      this.$refs.notificacao.show(texto, "#4caf50", tempo);
    },
    notificaoAviso(texto, tempo = 6000) {
      this.$refs.notificacao.show(texto, "#ffc107", tempo);
    },
    notificaoErro(texto, tempo = 6000) {
      this.$refs.notificacao.show(texto, "#eb4646", tempo);
    },
  },
  mounted() {
    var h = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    );
    // document.body.style.height = h + "px";
  },
};
</script>

