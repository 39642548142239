<template>
  <div>
    <Busca url="/ultimos-servicos" metodo="get" @dados="dados" ref="busca">
      <template slot="res">
        <div class="h-100">
          <div class="pt-5" v-if="servicos.length == 0">
            <h5 class="text-center" style="color: #003760">
              Nenhum serviço solicitado
            </h5>
          </div>
          <Card
            v-for="(s, i) in servicos"
            :key="i"
            :servico="s"
            :horario="true"
            @acao="cancelar(s)"
          ></Card>
          <div class="d-flex mt-3">
            <button
              class="btn btn-primary btn-block w-75 py-3 m-auto"
              @click="$emit('historico')"
            >
              Ver histórico de serviços
            </button>
          </div>
        </div>
      </template>
    </Busca>
    <div ref="modal" class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header" v-if="cancelando == 0">
            <h5 class="modal-title">Deseja cancelar o serviço?</h5>
          </div>
          <div class="modal-body">
            <div v-if="cancelando == 0">
              <Card
                v-if="servico"
                :servico="servico"
                status="AGUARDANDO"
                :horario="true"
              ></Card>
            </div>

            <div v-if="cancelando == 1">
              <h5 class="text-center">Cancelando</h5>
              <div style="display: flex">
                <div class="lds-ellipsis" style="margin: auto">
                  <div style="background: #003760"></div>
                  <div style="background: #003760"></div>
                  <div style="background: #003760"></div>
                  <div style="background: #003760"></div>
                </div>
              </div>
            </div>
            <div v-if="cancelando == 2">
              <h5 class="text-center text-success">Serviço cancelado</h5>
              <div style="display: flex" class="mt-3">
                <button
                  class="btn btn-primary"
                  style="margin: auto"
                  @click="
                    removerModal();
                    buscar();
                  "
                >
                  OK
                </button>
              </div>
            </div>
             <div v-if="cancelando == 3">
              <h5 class="text-center text-danger">O serviço não pode ser cancelado</h5>
              <div style="display: flex" class="mt-3">
                <button
                  class="btn btn-primary"
                  style="margin: auto"
                  @click="
                    removerModal();
                  "
                >
                  OK
                </button>
              </div>
            </div>
          </div>
          <div class="modal-footer" v-if="cancelando == 0">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click="removerModal"
            >
              Não
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="cancelarServico"
            >
              Sim
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 

<script>
import Busca from "../Busca.vue";
import Card from "./Card.vue";
export default {
  components: {
    Busca,
    Card,
  },
  data() {
    return {
      servicos: [],
      buscador: null,
      servico: null,
      cancelando: 0,
    };
  },
  methods: {
    removerModal() {
      $(this.$refs.modal).modal("hide");
      this.cancelando = 0;
    },
    cancelarServico() {
      this.cancelando = 1;
      api(
        "/cancelar-servico",
        "POST",
        {
          grupo: this.servico.servicos[0].grupo,
          embarcacao: this.servico.servicos[0].embarcacao_id,
        },
        (e) => {
          if (e.success) {
            this.cancelando = 2;
          } else {
            this.cancelando = 3;
          }
        },
        (e) => {
          this.cancelando = 3;
        },
        (e) => {
          this.cancelando = 3;
        }
      );
    },
    cancelar(s) {
      this.servico = s;
      $(this.$refs.modal).appendTo("body").modal("show");
    },
    dados(e) {
      this.servicos = e;
    },
    buscar() {
      this.$refs.busca.buscar();
    },
  },
  mounted() {
    this.buscador = setInterval(this.buscar, 60000);
  },
  destroyed() {
    clearInterval(this.buscador);
  },
  watch: {},
};
</script>