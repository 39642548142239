<template>
  <div class="h-100 container">
    <Servico
      v-if="acao == 'servico'"
      @historico="acao = 'embarcacao'"
    ></Servico>
    <Embarcacao
      v-if="acao == 'embarcacao'"
      @embarcacao="
        embarcacao = $event;
        acao = 'historico';
      "
    ></Embarcacao>
    <Historico v-if="acao == 'historico'" :embarcacao="embarcacao"></Historico>
  </div>
</template>

<script>
import Historico from "../lista/Historico.vue";
import Servico from "../lista/Servico.vue";
import Embarcacao from "../lista/Embarcacao.vue";
export default {
  components: {
    Historico,
    Servico,
    Embarcacao,
  },
  data() {
    return {
      acao: "servico",
      embarcacao: null,
    };
  },
  methods: {
    back() {
      if (this.acao == "servico") {
        this.$emit("main");
        return;
      }
      if (this.acao == "historico") {
        this.acao = "embarcacao";
      } else {
        this.acao = "servico";
      }
    },
    atualizar() {
      this.acao = "servico";
    },
  },
  watch: {
  },
};
</script>