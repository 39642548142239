<template>
  <div>
    <h4 class="text-center" style="color: #003760">Trocar senha</h4>
    <div class="card py-3 card-item">
      <div class="card-body">
        <div>
          Senha atual
          <input
            type="password"
            v-model="senhaAtual"
            placeholder="Senha atual"
            style="width: 100%"
          />
        </div>
        <div class="mt-3">
          Nova senha
          <input
            type="password"
            v-model="novaSenha"
            placeholder="Nova senha"
            style="width: 100%"
          />
        </div>
        <div class="mt-3">
          Repita a nova senha
          <input
            type="password"
            v-model="repitaNovaSenha"
            placeholder="Repita a nova senha"
            style="width: 100%"
          />
        </div>
        <button
          type="button"
          class="btn btn-primary btn-block w-100 mt-4"
          style="max-height: 38px; overflow: hidden"
          @click="trocarSenha"
          ref="enter"
          :disabled="
            trocando ||
            senhaAtual.length < 8 ||
            novaSenha.length < 8 ||
            repitaNovaSenha.length < 8
          "
        >
          <div class="lds-ellipsis" style="margin-top: -27px" v-if="trocando">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div v-else>Trocar</div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      senhaAtual: "",
      novaSenha: "",
      repitaNovaSenha: "",
      trocando: false,
    };
  },
  methods: {
    trocarSenha() {
      if (this.repitaNovaSenha != this.novaSenha) {
        this.$parent.$parent.$parent.$parent.notificaoErro("As senhas não são iguais");
        return;
      }
      this.trocando = true;
      api(
        "/trocar-senha",
        "post",
        {
          novaSenha: this.novaSenha,
          senhaAtual: this.senhaAtual,
        },
        (e) => {
          if (e.success) {
            this.$parent.$parent.$parent.$parent.notificaoSucesso(e.message);
          } else {
            this.$parent.$parent.$parent.$parent.notificaoErro(e.message);
          }
          this.trocando = false;
        },
        (e) => {
          this.trocando = false;
        },
        (e) => {
          this.trocando = false;
        }
      );
    },
  },
};
</script>