var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',{ref:"layout",on:{"acao":function($event){return _vm.lowerMenuAction($event)},"back":_vm.back}},[_c('template',{slot:"body"},[_c('div',{staticClass:"h-100"},[_c('Main',{directives:[{name:"show",rawName:"v-show",value:(_vm.acao == 'Main'),expression:"acao == 'Main'"}],ref:"main",on:{"servico":function($event){_vm.acao = 'Solicitacao';
          _vm.$refs.layout.acao = 'Solicitacao';},"conta":function($event){_vm.acao = 'Conta';
          _vm.$refs.layout.acao = 'Conta';},"historico":function($event){_vm.acao = 'Lista';
          _vm.$refs.layout.acao = 'Lista';},"embarcacao":function($event){_vm.acao = 'Conta';
          _vm.$refs.conta.acao = 'embarcacoes';},"pendencia":function($event){_vm.acao = 'Conta';
          _vm.$refs.conta.acao = 'pendencias';},"cobranca":function($event){_vm.acao = 'Conta';
          _vm.$refs.conta.acao = 'cobrancas';},"back":function($event){_vm.$refs.layout.back = $event}}}),(_vm.acao == 'Solicitacao')?_c('Solicitacao',{ref:"solicitacao",on:{"main":function($event){_vm.acao = 'Main';
          _vm.$refs.layout.acao = 'Main';
          _vm.$refs.main.acao = null;}}}):_vm._e(),(_vm.acao == 'Lista')?_c('Lista',{ref:"lista",on:{"back":function($event){_vm.$refs.layout.back = $event},"main":function($event){_vm.acao = 'Main';
          _vm.$refs.layout.acao = 'Main';}}}):_vm._e(),_c('Conta',{directives:[{name:"show",rawName:"v-show",value:(_vm.acao == 'Conta'),expression:"acao == 'Conta'"}],ref:"conta",on:{"logout":_vm.logout,"main":function($event){_vm.acao = 'Main';
          _vm.$refs.layout.acao = 'Main';},"back":function($event){_vm.$refs.layout.back = $event}}})],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }